import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FaEnvelope } from 'react-icons/fa'; // Importa l'icona della mail
import jsQR from 'jsqr';
import Cookies from 'js-cookie';
import logo from '../assets/logo.png';  // Importa il logo

const DISABLE_SCAN_IF_WORK_ACTIVE = true; // Imposta a `false` per disattivare la funzione

const QRCodeScanner = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState(null);
    const [userFullName, setUserFullName] = useState('');
    const [scanning, setScanning] = useState(false);
    const [qrCodeData, setQRCodeData] = useState(null);
    const [commessaData, setCommessaData] = useState(null);
    const [works, setWorks] = useState([]);
    const [completedWorks, setCompletedWorks] = useState([]);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [selectedWorkId, setSelectedWorkId] = useState(null);
    const [emailMessage, setEmailMessage] = useState('');

    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        const jwtToken = Cookies.get('jwtToken');
        if (jwtToken) {
            validateToken(jwtToken);
        }
    }, []);

    const validateToken = async (jwtToken) => {
        try {
            const response = await fetch('https://diesse-manager.my-services.it/api/users/me', {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setToken(jwtToken);
                setLoggedIn(true);
                setUserFullName(data.username);
                fetchUserWorks(jwtToken, data.id);
                fetchCompletedWorks(jwtToken, data.id);
            } else {
                Cookies.remove('jwtToken');
            }
        } catch (error) {
            console.error('Error validating token:', error);
        }
    };

    const handleLogin = async () => {
        try {
            const response = await fetch('https://diesse-manager.my-services.it/api/auth/local', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    identifier: username,
                    password: password,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.jwt) {
                Cookies.set('jwtToken', data.jwt, { expires: 1 });
                setToken(data.jwt);
                setLoggedIn(true);
                setUserFullName(data.user.username);
                fetchUserWorks(data.jwt, data.user.id);
                fetchCompletedWorks(data.jwt, data.user.id);
            } else {
                alert('Login failed');
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('Login non riuscito. Verifica le credenziali e riprova.');
        }
    };

    const handleLogout = () => {
        Cookies.remove('jwtToken');
        setLoggedIn(false);
        window.location.reload();
    };

    const fetchUserWorks = async (jwtToken, userId) => {
        try {
            const worksResponse = await fetch(
                `https://diesse-manager.my-services.it/api/works?filters[users_permissions_user][id][$eq]=${userId}&filters[fine][$null]=true&filters[commesse][abilitata][$eq]=true&populate=commesse`,
                {
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                    },
                }
            );

            if (!worksResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const worksData = await worksResponse.json();
            console.log(worksData.data);
            setWorks(worksData.data);
        } catch (error) {
            console.error('Error fetching works:', error);
            alert('There was an issue fetching the works data. Please try again.');
        }
    };

    const fetchCompletedWorks = async (jwtToken, userId) => {
        try {
            const worksResponse = await fetch(
                `https://diesse-manager.my-services.it/api/works?filters[users_permissions_user][id][$eq]=${userId}&filters[fine][$notNull]=true&filters[commesse][abilitata][$eq]=true&populate=commesse&sort=id:desc&pagination[limit]=30`,
                {
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                    },
                }
            );

            if (!worksResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const worksData = await worksResponse.json();
            setCompletedWorks(worksData.data);
        } catch (error) {
            console.error('Error fetching completed works:', error);
            alert('There was an issue fetching the completed works data. Please try again.');
        }
    };

    const startScan = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('getUserMedia is not supported by your browser.');
            return;
        }

        try {
            setScanning(true);
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
            videoRef.current.srcObject = stream;
            videoRef.current.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
            videoRef.current.play();

            const tick = () => {
                if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
                    const canvasElement = canvasRef.current;
                    const canvas = canvasElement.getContext('2d');
                    canvasElement.height = videoRef.current.videoHeight;
                    canvasElement.width = videoRef.current.videoWidth;
                    canvas.drawImage(videoRef.current, 0, 0, canvasElement.width, canvasElement.height);

                    const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
                    const code = jsQR(imageData.data, imageData.width, imageData.height, {
                        inversionAttempts: 'dontInvert',
                    });

                    if (code) {
                        const cleanedQRCodeData = code.data;
                        setQRCodeData(cleanedQRCodeData);
                        setScanning(false);
                        stream.getTracks().forEach(track => track.stop());

                        // Fetch the commessa details immediately after scanning
                        fetchCommessaDetails(cleanedQRCodeData);
                    } else {
                        requestAnimationFrame(tick);
                    }
                } else {
                    requestAnimationFrame(tick);
                }
            };

            requestAnimationFrame(tick);
        } catch (error) {
            console.error('Error accessing the camera:', error);
            alert('Unable to access the camera. Please check your browser permissions.');
        }
    };

    const fetchCommessaDetails = async (idCommessa) => {
        try {
            const commessaResponse = await fetch(
                `https://diesse-manager.my-services.it/api/commesses?filters[id_commessa][$eq]=${idCommessa}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (!commessaResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const commessaJson = await commessaResponse.json();
            if (commessaJson.data.length === 0) {
                alert('No commessa found with the given ID.');
                setCommessaData(null);
            } else {
                setCommessaData(commessaJson.data[0].attributes);
            }
        } catch (error) {
            console.error('Error fetching commessa details:', error);
            alert('There was an issue fetching the commessa details. Please try again.');
        }
    };

    const handleStartCommessa = async () => {
        if (!token) {
            alert('User not logged in.');
            return;
        }

        try {
            const userResponse = await fetch('https://diesse-manager.my-services.it/api/users/me', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!userResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const userData = await userResponse.json();
            const userId = userData.id;

            const commessaResponse = await fetch(
                `https://diesse-manager.my-services.it/api/commesses?filters[id_commessa][$eq]=${qrCodeData}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (!commessaResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const commessaJson = await commessaResponse.json();
            if (commessaJson.data.length === 0) {
                alert('No commessa found with the given ID.');
                return;
            }

            const commessaId = commessaJson.data[0].id;

            const postData = {
                data: {
                    Note: null,
                    inizio: new Date().toISOString(),
                    fine: null,
                    commesse: commessaId,
                    users_permissions_user: userId,
                },
            };

            const workResponse = await fetch('https://diesse-manager.my-services.it/api/works', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(postData),
            });

            if (!workResponse.ok) {
                throw new Error('Network response was not ok');
            }

            alert('Task commessa avviato con successo!');

            // Pulizia dello stato di commessaData dopo l'avvio della commessa
            setCommessaData(null);
            setQRCodeData(null);

            fetchUserWorks(token, userId);
        } catch (error) {
            console.error('Commessa creation error:', error);
            alert('Si è verificato un problema nella creazione del task di questa commessa. Riprova più tardi.');
        }
    };

    const handleStopWork = async (workId) => {
        if (!token) {
            alert('Utente non loggato.');
            return;
        }

        try {
            const patchData = {
                data: {
                    fine: new Date().toISOString(),
                },
            };

            const stopResponse = await fetch(`https://diesse-manager.my-services.it/api/works/${workId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(patchData),
            });

            if (!stopResponse.ok) {
                throw new Error('Network response was not ok');
            }

            fetchUserWorks(token, workId);
            fetchCompletedWorks(token, workId);
        } catch (error) {
            console.error('Error stopping work:', error);
            alert('Si è verificato un problema nello stop del task. Riprova più tardi.');
        }
    };

    const calculateHours = (inizio, fine) => {
        const startTime = new Date(inizio);
        const endTime = new Date(fine);
        const totalSeconds = Math.abs(endTime - startTime) / 1000;

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);

        return `${hours}:${minutes.toString().padStart(2, '0')}`;
    };



    const handleOpenEmailModal = (workId) => {
        setSelectedWorkId(workId);
        setShowEmailModal(true);
    };

    const handleSendEmail = async () => {
        if (!emailMessage || !selectedWorkId) return;

        try {
            const response = await fetch(`https://diesse-manager.my-services.it/api/works/${selectedWorkId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ data: { Note: emailMessage } }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            alert("Nota inviata con successo!");
            setShowEmailModal(false);
            setEmailMessage('');
        } catch (error) {
            console.error("Error sending email:", error);
            alert("Errore durante l'invio del messaggio. Riprova.");
        }
    };

    const handleCloseEmailModal = () => {
        setShowEmailModal(false);
        setEmailMessage('');
    };

    return (
        <div>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src={logo} alt="Logo" style={{ maxHeight: '100px' }} />
            </div>

            {loggedIn ? (
                <div>
                    <h2>Ciao, {userFullName}</h2>
                    <Button label="Logout" className="p-button-secondary" onClick={handleLogout} />
                </div>
            ) : null}

            {!loggedIn && (
                <Dialog header="Login" visible={!loggedIn} modal onHide={() => {}} closable={false}>
                    <div className="p-fluid">
                        <div className="p-field">
                            <label htmlFor="username">Username</label>
                            <input
                                id="username"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="p-field">
                            <label htmlFor="password">Password</label>
                            <input
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <Button label="Login" onClick={handleLogin} />
                    </div>
                </Dialog>
            )}

            {loggedIn && !scanning && (!DISABLE_SCAN_IF_WORK_ACTIVE || works.length === 0) && (
                <div>
                    <Button label="Scansiona QR Commessa" onClick={startScan} />
                </div>
            )}

            {scanning && (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <video ref={videoRef} style={{ width: '100%', maxWidth: '500px', border: '1px solid black' }} />
                        <canvas ref={canvasRef} style={{ display: 'none' }} />
                    </div>
                </div>
            )}

            {commessaData && (
                <div>
                    <h3>Commessa: <strong>{commessaData.Titolo}</strong></h3>
                    {commessaData.abilitata ? (
                        <div>
                            {commessaData.oggetto && (
                                <p>Tipologia: <strong>{commessaData.oggetto}</strong></p>
                            )}
                            {commessaData.descrizione && (
                                <p>Descrizione: <strong>{commessaData.descrizione}</strong></p>
                            )}
                            {commessaData.cliente && (
                                <p>Cliente: <strong>{commessaData.cliente}</strong></p>
                            )}
                            {commessaData.note && (
                                <div>
                                    <h3>Note:</h3>
                                    <div dangerouslySetInnerHTML={{ __html: commessaData.note }} />
                                </div>
                            )}
                            {commessaData.link && (
                                <p>Link: <a target="_blank" href={commessaData.link}>Link al materiale sul cloud</a></p>
                            )}
                        </div>
                    ) : (
                        <p style={{ fontWeight: 'bold', color: 'red' }}>COMMESSA NON ATTIVA</p>
                    )}
                </div>
            )}

            {commessaData && commessaData.abilitata && qrCodeData && (
                <Button label="Avvia questa commessa" className="p-button-success" onClick={handleStartCommessa} />
            )}

            {loggedIn && works.length > 0 && (
                <div>
                    <h2>Commessa Attive</h2>
                        {works.map((work) => (
                            <div>
                                <div>ID: {work.id}</div>
                                <div>Inizio: {new Date(work.attributes.inizio).toLocaleString()}</div>
                                <div>
                                    <Button
                                        label="Stop"
                                        className="p-button-danger"
                                        onClick={() => handleStopWork(work.id)}
                                    />
                                </div>
                                <div>
                                    {work.attributes.commesse.data.attributes.oggetto && (
                                        <p>Tipologia: <strong>{work.attributes.commesse.data.attributes.oggetto}</strong></p>
                                    )}
                                    {work.attributes.commesse.data.attributes.descrizione && (
                                        <p>Descrizione: <strong>{work.attributes.commesse.data.attributes.descrizione}</strong></p>
                                    )}
                                    {work.attributes.commesse.data.attributes.cliente && (
                                        <p>Cliente: <strong>{work.attributes.commesse.data.attributes.cliente}</strong></p>
                                    )}
                                    {work.attributes.commesse.data.attributes.note && (
                                        <div>
                                            <h3>Note:</h3>
                                            <div dangerouslySetInnerHTML={{ __html: work.attributes.commesse.data.attributes.note }} />
                                        </div>
                                    )}
                                    {work.attributes.commesse.data.attributes.link && (
                                        <p>Link: <a target="_blank" href={work.attributes.commesse.data.attributes.link}>Link al materiale sul cloud</a></p>
                                    )}
                                </div>
                            </div>
                        ))}

                </div>
            )}

            {loggedIn && completedWorks.length > 0 && (
                <div>
                    <h2>Commesse Completate</h2>
                    <table className="p-datatable">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Commessa</th>
                                <th>Inizio</th>
                                <th>Fine</th>
                                <th>Ore</th>
                                <th>Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            {completedWorks.map((work) => (
                                <tr key={work.id}>
                                    <td>{work.id}</td>
                                    <td>{work.attributes.commesse.data.attributes.Titolo}</td>
                                    <td>{new Date(work.attributes.inizio).toLocaleString()}</td>
                                    <td>{new Date(work.attributes.fine).toLocaleString()}</td>
                                    <td>{calculateHours(work.attributes.inizio, work.attributes.fine)}</td>
                                    <td>
                                        {!work.attributes.Note && (
                                            <FaEnvelope
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleOpenEmailModal(work.id)}
                                            />
                                        )}
                                        {work.attributes.Note && (
                                            <p>{work.attributes.Note}</p>
                                        )}

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <Dialog header="Invia Nota" visible={showEmailModal} modal onHide={handleCloseEmailModal}>
                <div className="p-field">
                    <label htmlFor="emailMessage">Messaggio</label>
                    <textarea
                        id="emailMessage"
                        rows="5"
                        value={emailMessage}
                        onChange={(e) => setEmailMessage(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </div>
                <Button label="Invia" onClick={handleSendEmail} />
            </Dialog>
        </div>
    );
};

export default QRCodeScanner;
