import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Password } from "primereact/password";
import { Accordion, AccordionTab } from 'primereact/accordion';

//import { Badge } from 'primereact/badge';
import { FaEnvelope } from 'react-icons/fa'; // Importa l'icona della mail
import jsQR from 'jsqr';
import Cookies from 'js-cookie';
import logo from '../assets/logo.png';  // Importa il logo

const DISABLE_SCAN_IF_WORK_ACTIVE = true; // Imposta a `false` per disattivare la funzione

const URL = "https://diesse-manager.my-services.it";
//const url = "http://localhost:3028";

const QRCodeScanner = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRipeti, setPasswordRipeti] = useState('');
    const [passwordCorrente, setPasswordCorrente] = useState('');
    const [token, setToken] = useState(null);
    const [userFullName, setUserFullName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [scanning, setScanning] = useState(false);
    const [qrCodeData, setQRCodeData] = useState(null);
    const [commessaData, setCommessaData] = useState(null);
    const [works, setWorks] = useState([]);
    const [completedWorks, setCompletedWorks] = useState([]);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showNotaModal, setShowNotaModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedWorkId, setSelectedWorkId] = useState(null);
    const [selectedNotaId, setSelectedNotaId] = useState(null);
    const [emailMessage, setEmailMessage] = useState('');
    const [notaMessage, setNotaMessage] = useState('');

    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        const jwtToken = Cookies.get('jwtToken');
        if (jwtToken) {
            validateToken(jwtToken);
        }

        // Controlla ogni minuto se è l'ora di ricaricare la pagina
        const checkReloadTime = setInterval(() => {
            const now = new Date();
            const currentMinutes = now.getHours() * 60 + now.getMinutes();
            const reloadTimes = [12 * 60 + 3, 18 * 60 + 3]; // Minuti delle 14:05 e 18:05
            //const reloadTimes = [18 * 60 + 3]; // Minuti delle 18:05

            if (reloadTimes.includes(currentMinutes)) {
                console.log("Reloading the page due to scheduled time.");
                window.location.reload();
            }
        }, 30000); // Controlla ogni minuto

        // Pulisce l'intervallo quando il componente si smonta
        return () => clearInterval(checkReloadTime);

    }, []);

    const getUserIdByUsername = async (username, jwtToken) => {
        try {
            const response = await fetch(`${URL}/api/users?filters[username]=${username}`, {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            });

            if (!response.ok) {
              throw new Error(`Errore durante il recupero dell'utente: ${response.statusText}`);
            }

            const users = await response.json();

            // Assicurati che almeno un utente sia trovato
            if (users.length === 0) {
              throw new Error("Utente non trovato");
            }

            return users[0].id; // Restituisci il primo ID trovato
          } catch (error) {
            console.error("Errore durante il recupero dell'utente:", error.message);
            return null;
          }
    }


    const validateToken = async (jwtToken) => {
        try {
            const response = await fetch(`${URL}/api/users/me`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setToken(jwtToken);
                setLoggedIn(true);
                setUserFullName(data.username);
                setUserEmail(data.email);
                fetchUserWorks(jwtToken, data.id);
                fetchCompletedWorks(jwtToken, data.id);
            } else {
                Cookies.remove('jwtToken');
            }
        } catch (error) {
            console.error('Error validating token:', error);
        }
    };

    const handleLogin = async () => {
        try {
            const response = await fetch(`${URL}/api/auth/local`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    identifier: username,
                    password: password,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.jwt) {
                Cookies.set('jwtToken', data.jwt, { expires: 14 });
                setToken(data.jwt);
                setLoggedIn(true);
                setUserFullName(data.user.username);
                fetchUserWorks(data.jwt, data.user.id);
                fetchCompletedWorks(data.jwt, data.user.id);
            } else {
                alert('Login failed');
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('Login non riuscito. Verifica le credenziali e riprova.');
        }
    };

    const handleLogout = () => {
        Cookies.remove('jwtToken');
        setLoggedIn(false);
        window.location.reload();
    };

    const fetchUserWorks = async (jwtToken, userId) => {
        try {
            setUserId(userId);

            const worksResponse = await fetch(
                `${URL}/api/works?filters[users_permissions_user][id][$eq]=${userId}&filters[fine][$null]=true&filters[commesse][abilitata][$eq]=true&populate[commesse][populate]=clienti,noteOperatori.users_permissions_user`,
                {
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                    },
                }
            );

            if (!worksResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const worksData = await worksResponse.json();
            console.log(worksData.data);
            setWorks(worksData.data);
        } catch (error) {
            console.error('Error fetching works:', error);
            alert('There was an issue fetching the works data. Please try again.');
        }
    };

    const fetchCompletedWorks = async (jwtToken, userId) => {
        try {
            const worksResponse = await fetch(
                `${URL}/api/works?filters[users_permissions_user][id][$eq]=${userId}&filters[fine][$notNull]=true&filters[commesse][abilitata][$eq]=true&populate=commesse&sort=id:desc&pagination[limit]=30`,
                {
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                    },
                }
            );

            if (!worksResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const worksData = await worksResponse.json();
            setCompletedWorks(worksData.data);
        } catch (error) {
            console.error('Error fetching completed works:', error);
            alert('There was an issue fetching the completed works data. Please try again.');
        }
    };

    const startScan = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('getUserMedia is not supported by your browser.');
            return;
        }

        try {
            setScanning(true);
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
            videoRef.current.srcObject = stream;
            videoRef.current.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
            videoRef.current.play();

            const tick = () => {
                if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
                    const canvasElement = canvasRef.current;
                    const canvas = canvasElement.getContext('2d');
                    canvasElement.height = videoRef.current.videoHeight;
                    canvasElement.width = videoRef.current.videoWidth;
                    canvas.drawImage(videoRef.current, 0, 0, canvasElement.width, canvasElement.height);

                    const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
                    const code = jsQR(imageData.data, imageData.width, imageData.height, {
                        inversionAttempts: 'dontInvert',
                    });
                    if (code) {
                        const cleanedQRCodeData = code.data;
                        console.log(code);
                        setQRCodeData(cleanedQRCodeData);
                        setScanning(false);
                        stream.getTracks().forEach(track => track.stop());

                        // Fetch the commessa details immediately after scanning
                        console.log(cleanedQRCodeData);
                        fetchCommessaDetails(cleanedQRCodeData);
                    } else {
                        requestAnimationFrame(tick);
                    }
                } else {
                    requestAnimationFrame(tick);
                }
            };

            requestAnimationFrame(tick);
        } catch (error) {
            console.error('Error accessing the camera:', error);
            alert('Unable to access the camera. Please check your browser permissions.');
        }
    };

    const fetchCommessaDetails = async (idCommessa) => {
        try {
            console.log(idCommessa);
            const commessaResponse = await fetch(
                `${URL}/api/commesses?filters[id_commessa][$eq]=${idCommessa}&populate=clienti,noteOperatori.users_permissions_user`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (!commessaResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const commessaJson = await commessaResponse.json();
            console.log(commessaJson);
            if (commessaJson.data.length === 0) {
                alert('Nessuna commessa trovata con ID: ' + idCommessa);
                setCommessaData(null);
            } else {
                let datas = commessaJson.data[0].attributes;
                datas.id = commessaJson.data[0].id;
                setCommessaData(commessaJson.data[0].attributes);
            }
        } catch (error) {
            console.error('Error fetching commessa details:', error);
            alert('There was an issue fetching the commessa details. Please try again.');
        }
    };

    const handleStartCommessa = async () => {
        if (!token) {
            alert('User not logged in.');
            return;
        }

        try {
            const userResponse = await fetch(`${URL}/api/users/me`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!userResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const userData = await userResponse.json();
            const userId = userData.id;

            const commessaResponse = await fetch(
                `${URL}/api/commesses?filters[id_commessa][$eq]=${qrCodeData}&populate=clienti,noteOperatori`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (!commessaResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const commessaJson = await commessaResponse.json();
            if (commessaJson.data.length === 0) {
                alert('Nessuna commessa trovata con ID: ' + qrCodeData);
                return;
            }

            const commessaId = commessaJson.data[0].id;

            const postData = {
                data: {
                    Note: null,
                    inizio: new Date().toISOString(),
                    fine: null,
                    commesse: commessaId,
                    users_permissions_user: userId,
                },
            };

            const workResponse = await fetch(`${URL}/api/works`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(postData),
            });

            if (!workResponse.ok) {
                throw new Error('Network response was not ok');
            }

            //alert('Task commessa avviato con successo!');
            window.location.reload();

            // Pulizia dello stato di commessaData dopo l'avvio della commessa
            //setCommessaData(null);
            //setQRCodeData(null);

            fetchUserWorks(token, userId);
        } catch (error) {
            console.error('Commessa creation error:', error);
            alert('Si è verificato un problema nella creazione del task di questa commessa. Riprova più tardi.');
        }
    };

    const handleStopWork = async (workId) => {
        if (!token) {
            alert('Utente non loggato.');
            return;
        }

        try {
            const patchData = {
                data: {
                    fine: new Date().toISOString(),
                },
            };

            const stopResponse = await fetch(`${URL}/api/works/${workId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(patchData),
            });

            if (!stopResponse.ok) {
                throw new Error('Network response was not ok');
            }

            fetchUserWorks(token, workId);
            fetchCompletedWorks(token, workId);
            window.location.reload();
        } catch (error) {
            console.error('Error stopping work:', error);
            alert('Si è verificato un problema nello stop del task. Riprova più tardi.');
        }
    };

    const calculateHours = (inizio, fine) => {
        const startTime = new Date(inizio);
        const endTime = new Date(fine);
        const totalSeconds = Math.abs(endTime - startTime) / 1000;

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);

        return `${hours}:${minutes.toString().padStart(2, '0')}`;
    };


    const handleOpenNotaModal = (commessaId) => {
        console.log(`commessa id ${commessaId}`)
        setSelectedNotaId(commessaId);
        setShowNotaModal(true);
    };

    const handleOpenEmailModal = (workId) => {
        setSelectedWorkId(workId);
        setShowEmailModal(true);
    };

    const handleOpenChangePasswordModal = (User) => {
        setSelectedUser(User);
        setShowChangePasswordModal(true);
    };

    const handleSendNota = async () => {
        console.log(selectedNotaId);
        if (!notaMessage || !selectedNotaId) return;
        console.log(notaMessage);

        try {
            // Step 1: Recupera i dati attuali della commessa
            const getResponse = await fetch(`${URL}/api/commesses/${selectedNotaId}?populate=noteOperatori&populate=clienti`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!getResponse.ok) {
                throw new Error('Failed to fetch current work data');
            }

            const currentData = await getResponse.json();
            console.log(currentData);
            const currentNotes = currentData?.data?.attributes?.noteOperatori || [];
            const currentCommessa = currentData.data.attributes.Titolo || "N/A";
            const currentCliente = currentData.data.attributes.clienti.data.attributes.cliente || "N/A";

            // Step 2: Prepara la nuova nota
            const newNote = {
                nota: notaMessage,
                users_permissions_user: userId, // ID utente da associare
                data: new Date().toISOString(), // Data attuale
            };

            // Step 3: Aggiungi la nuova nota all'array esistente
            const updatedNotes = [...currentNotes, newNote];

            // Step 4: Esegui il PUT per aggiornare la commessa
            const putResponse = await fetch(`${URL}/api/commesses/${selectedNotaId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    data: {
                        noteOperatori: updatedNotes,
                        messaggio: notaMessage,
                        user: userFullName,
                        commessa: currentCommessa,
                        cliente: currentCliente,
                    },
                }),
            });

            if (!putResponse.ok) {
                throw new Error('Failed to update work with new note');
            }

            // Success: Chiudi il modal e aggiorna la UI
            setShowNotaModal(false);
            setNotaMessage('');
            window.location.reload();
        } catch (error) {
            console.error('Error adding note to work:', error);
            alert('Errore durante l\'aggiunta della nota. Riprova.');
        }
    };


    const handleSendEmail = async () => {
        if (!emailMessage || !selectedWorkId) return;

        try {
            const response = await fetch(`${URL}/api/works/${selectedWorkId}`, {
            //const response = await fetch(`http://localhost:3028/api/works/${selectedWorkId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ data: { Note: emailMessage, user: userFullName, email: userEmail } }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            //alert("Nota inviata con successo!");
            setShowEmailModal(false);
            setEmailMessage('');
            window.location.reload();
        } catch (error) {
            console.error("Error sending email:", error);
            alert("Errore durante l'invio del messaggio. Riprova.");
        }
    };

    const handleChangePasswordEmail = async () => {
        if (!setPassword || !selectedUser || !setPasswordCorrente) return;
          try {
            const response = await fetch(`${URL}/api/auth/change-password`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`, // Token utente
              },
              body: JSON.stringify({
                currentPassword: passwordCorrente,
                password: password,
                passwordConfirmation: passwordRipeti, // Deve corrispondere alla nuova password
              }),
            });

            if (!response.ok) {
              const error = await response.json();
              throw new Error(`Errore: ${error.message}`);
            }

            const data = await response.json();
            console.log('Password aggiornata con successo:', data);
            alert("Password aggiornata con successo");
            setShowChangePasswordModal(false);
            setPassword("");
            setPasswordCorrente("");
            setPasswordRipeti("");
          } catch (error) {
            alert("Errore durante il cambio password");
            console.error('Errore durante il cambio password:', error.message);
          }
    };

    const handleCloseNotaModal = () => {
        setShowNotaModal(false);
        setNotaMessage('');
    };

    const handleCloseEmailModal = () => {
        setShowEmailModal(false);
        setEmailMessage('');
    };

    const handleCloseChangePasswordModal = () => {
        setShowChangePasswordModal(false);
        setPassword('');
        setPasswordCorrente('');
    };

    return (
        <div className='page-container'>
            
            <div className='header'>
                <img src={logo} alt="Logo" style={{ width: '60px' }} />
                {loggedIn ? (
                <div className='logout-container'>
                    <div className="flex flex-row user-password">
                        <span className='username'>{userFullName}</span>
                        <Button severity="info" icon="pi pi-user" iconPos="left" onClick={() => handleOpenChangePasswordModal(userFullName)} size="small" rounded/>
                    </div>
                    <Button label="Esci" severity="warning" icon="pi pi-sign-out" iconPos="left" onClick={handleLogout} size="small"/>
                </div>
                ) : null}

            </div>
            

            

            {!loggedIn && (
                // LOGIN
                <Dialog header="Login" visible={!loggedIn} modal onHide={() => {}} closable={false}>
                    <div className="p-fluid">
                        <div className="p-field">
                            <label htmlFor="username">Username</label>
                            <input
                                id="username"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="p-field">
                            <label htmlFor="password">Password</label>
                            <input
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <Button label="Login" onClick={handleLogin} />
                    </div>
                </Dialog>
            )}

            {loggedIn && !scanning && (!DISABLE_SCAN_IF_WORK_ACTIVE || works.length === 0) && (
                <div className='scansiona'>
                    <Button label="Scansiona QR Commessa" severity="success" rounded  onClick={startScan} size='large' icon='pi pi-qrcode
' iconPos='left' />
                </div>
            )}

            {scanning && (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <video ref={videoRef} style={{ width: '100%', maxWidth: '500px', border: '1px solid black' }} />
                        <canvas ref={canvasRef} style={{ display: 'none' }} />
                    </div>
                </div>
            )}
            
            
            
            {commessaData && (   
                <div className='comessa-scanned--container'> 
                <div className='commessa-scanned--inner'>
                    
                <div className='w100'>
                    <h3 className='commessa-title'>{commessaData.Titolo}</h3>
                    {commessaData.abilitata ? (
                        <div>
                            {commessaData.oggetto && (
                                <div className='commessa-scanned--block'>
                                    <span className='commessa-scanned--label'>
                                        Tipologia
                                    </span>
                                    <span>{commessaData.oggetto}</span>
                                </div>
                            )}
                            {commessaData.descrizione && (
                                <div className='commessa-scanned--block'>
                                    <span className='commessa-scanned--label'>
                                    Descrizione
                                    </span>
                                    <span>{commessaData.descrizione}</span>
                                </div>
                                
                            )}
                            {commessaData.clienti.cliente &&
                                commessaData.clienti && (
                                    <div className='commessa-scanned--block'>
                                        <span className='commessa-scanned--label'>
                                        Cliente
                                        </span>
                                        <span>{commessaData.clienti.cliente}</span>
                                    </div>
                                
                            )}
                            {commessaData.noteOperatori && commessaData.noteOperatori[0] && (
                                <div className='commessa-scanned--block border-top'>
                                    <span className='commessa-scanned--label commessa--note'>
                                        Note
                                    </span>
                                    {commessaData.noteOperatori.map((nota) => (
                                        
                                        <div className='nota-container'>
                                                    <span className='nota-date'>
                                                    {new Intl.DateTimeFormat("it-IT", { dateStyle: "short", timeStyle: "short" }).format(new Date(nota.data))} </span>
                                                    <span className='nota-user'>{nota.users_permissions_user.data.attributes.username}</span> <br/>
                                                    
                                                    <span className='nota-text'>
                                                        {nota.nota}
                                                    </span>
                                        </div>
                                    ))}
                                </div>
                                
                            )}
                            <div className='nota-condivisa'>
                                <Button label="Scrivi nota condivisa" size='small' rounded className="p-button-help" icon="pi pi-pencil" onClick={() => handleOpenNotaModal(commessaData.id)} />
                            </div>

                            {commessaData.note && (
                                <div className='commessa-scanned--block border-top'>
                                    <span className='commessa-scanned--label'>
                                        Indicazioni
                                    </span>
                                    <div className='indicazioni-txt' dangerouslySetInnerHTML={{ __html: commessaData.note }} />
                                </div>
                                
                            )}
                            {commessaData.link && (
                                <a href={commessaData.link} className='p-button p-button-info mb10' target="_blank" >
                                    <i className="pi pi-folder mr10"></i> Vai ai documenti della commessa
                                </a>                                
                            )}
                            
                            <div className='commessa-scanned--footer'>
                                <Button label="Avvia commessa" size='large' rounded className="p-button-success" icon="pi pi-caret-right" onClick={handleStartCommessa} />
                            </div>
                        </div>
                    ) : (
                        <p className='non-active'><i className='pi pi-ban '></i> COMMESSA NON ATTIVA</p>
                    )}
                    
                </div>
                
                </div>
                </div>
                
            )}

                
            
            
            

            {loggedIn && works.length > 0 && (
                <div className='comessa-scanned--container'>
                    <h3>Commesse Attive</h3>
                
                    
                        {works.map((work) => (
                            <div className='commessa-scanned--inner active'>
                            <div className='w100'>
                                
                                
                                <div className='commessa-scanned--header'>
                                        <div className='commessa-scanned--header-title'>
                                            <span className='commessa-id'>{work.id}</span>                                                                                
                                            <h4>{work.attributes.commesse.data.attributes.Titolo}</h4>
                                        </div>
                                        <div className='commessa-scanned--block mb0'>
                                            <span className='commessa-scanned--label'>
                                            Inizio
                                            </span>
                                            <div>{new Intl.DateTimeFormat("it-IT", { dateStyle: "short", timeStyle: "short" }).format(new Date(work.attributes.inizio))}</div>

                                        </div>
                                        
                                </div>
                                
                                <div className='mt10'>
                                    {work.attributes.commesse.data.attributes.oggetto && (
                                        <div className='commessa-scanned--block'>
                                        <span className='commessa-scanned--label'>
                                        Tipologia
                                        </span>
                                        <span>{work.attributes.commesse.data.attributes.oggetto}</span>
                                    </div>
                                        
                                    )}
                                    {work.attributes.commesse.data.attributes.descrizione && (
                                        <div className='commessa-scanned--block'>
                                        <span className='commessa-scanned--label'>
                                        Descrizione
                                        </span>
                                        <span>{work.attributes.commesse.data.attributes.descrizione}</span>
                                    </div>
                                        
                                    )}
                                    {work.attributes.commesse.data.attributes.clienti &&
                                        work.attributes.commesse.data.attributes.clienti.data &&
                                        work.attributes.commesse.data.attributes.clienti.data.attributes.cliente && (
                                        <div className='commessa-scanned--block'>
                                        <span className='commessa-scanned--label'>
                                        Cliente
                                        </span>
                                        <span>{work.attributes.commesse.data.attributes.clienti.data.attributes.cliente}</span>
                                    </div>
                                        
                                    )}
                                    {work.attributes.commesse.data.attributes.noteOperatori && work.attributes.commesse.data.attributes.noteOperatori[0] && (
                                        <Accordion activeIndex={0}>
                                            <AccordionTab header="note">
                                            {work.attributes.commesse.data.attributes.noteOperatori.map((nota) => (
                                                <div className='nota-container'>
                                                    <span className='nota-date'>
                                                    {new Intl.DateTimeFormat("it-IT", { dateStyle: "short", timeStyle: "short" }).format(new Date(nota.data))} </span>
                                                    <span className='nota-user'>{nota.users_permissions_user.data.attributes.username}</span> <br/>
                                                    
                                                    <span className='nota-text'>
                                                        {nota.nota}
                                                    </span>
                                                </div>
                                             ))}
                                            </AccordionTab>
                                            
                                        </Accordion>
                                        
                                    )}
                                    <div className='nota-condivisa-2'>
                                        <Button label="Scrivi nota condivisa" size='small' rounded className="p-button-help" icon="pi pi-pencil" onClick={() => handleOpenNotaModal(work.attributes.commesse.data.id)} />
                                    </div>
                                    {work.attributes.commesse.data.attributes.note && (
                                        <Accordion activeIndex={0}>
                                            <AccordionTab header="indicazioni">
                                                <div dangerouslySetInnerHTML={{ __html: work.attributes.commesse.data.attributes.note }} />
                                            </AccordionTab>
                                        </Accordion>
                                    )}
                                    {work.attributes.commesse.data.attributes.link && (
                                        
                                        <a href={work.attributes.commesse.data.attributes.link} className='p-button p-button-info mb10' target="_blank" >
                                            <i className="pi pi-folder mr10"></i> Vai ai documenti della commessa
                                        </a>  
                                    )}
                                    
                                </div>
                                <div className='commessa-scanned--footer'>
                                    <Button
                                        label="Stop"
                                        className="p-button-danger"
                                        onClick={() => handleStopWork(work.id)}
                                        icon="pi pi-power-off"
                                        rounded
                                    />
                                    <p>tutte le commesse si disattiveranno automaticamente alle 12:00 e alle 18:00</p>
                                </div>
                            </div>
                            </div>
                        ))}

                </div>
                
            )}

            {loggedIn && completedWorks.length > 0 && (
                <div className='commessa-completed--container'>
                    <h3>Commesse Completate</h3>
                    
                            {completedWorks.map((work) => (
                                <div className='commessa-completed' key={work.id}>
                                    <div className='commessa-completed--header'>
                                        <span className='commessa-id'>{work.id}</span>
                                        
                                        <h4>{work.attributes.commesse.data.attributes.Titolo}</h4>
                                    </div>
                                    <div className='commessa-completed--time'>
                                        <div className='commessa-completed--time-detail'>
                                            <span>Inizio</span>
                                            <span>{new Intl.DateTimeFormat("it-IT", { dateStyle: "short", timeStyle: "short" }).format(new Date(work.attributes.inizio))}</span>
                                            <span></span>
                                        </div>
                                        <div className='commessa-completed--time-detail'>
                                            <span>Fine</span>
                                            <span>{new Intl.DateTimeFormat("it-IT", { dateStyle: "short", timeStyle: "short" }).format(new Date(work.attributes.fine))}</span>
                                            <span></span>
                                        </div>
                                        <div className='commessa-completed--time-detail'>
                                            <span>Durata</span>
                                            <span>{calculateHours(work.attributes.inizio, work.attributes.fine)}</span>
                                            <span></span>
                                        </div>
                                        
                                    </div>
                                    <div className='commessa-completed--notes'>
                                        
                                        <span>
                                        {!work.attributes.Note && (
                                            
                                            <Button
                                                onClick={() => handleOpenEmailModal(work.id)}
                                                icon="pi pi-pencil"
                                                size='small'
                                                severity='info'
                                                label='Aggiungi nota riservata'
                                            >

                                            </Button>
                                        )}
                                        {work.attributes.Note && (
                                            <div>
                                                <span>Note:</span>
                                                <p>{work.attributes.Note}</p>
                                            </div>
                                            
                                        )}
                                        </span>
                                    </div>


                                </div>
                            ))}
                   
                </div>
            )}

            <Dialog header="Scrivi nota condivisa" visible={showNotaModal} modal onHide={handleCloseNotaModal}>
                <div className="p-field">
                    <label htmlFor="notaMessage">Nota</label>
                    <textarea
                        id="notaMessage"
                        rows="5"
                        value={notaMessage}
                        onChange={(e) => setNotaMessage(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </div>
                <Button label="Invia nota condivisa" onClick={handleSendNota} />
            </Dialog>



            <Dialog header="Invia Nota" visible={showEmailModal} modal onHide={handleCloseEmailModal}>
                <div className="p-field">
                    <label htmlFor="emailMessage">Messaggio</label>
                    <textarea
                        id="emailMessage"
                        rows="5"
                        value={emailMessage}
                        onChange={(e) => setEmailMessage(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </div>
                <Button label="Invia" onClick={handleSendEmail} />
            </Dialog>

            <Dialog header="Cambia password" visible={showChangePasswordModal} modal onHide={handleCloseChangePasswordModal}>
                <div className="p-field">
                    <label htmlFor="passwordCorrente">Password Corrente</label>
                    <Password
                        inputId="passwordCorrente"
                        promptLabel="Password Corrente"
                        value={passwordCorrente}
                        onChange={(e) => setPasswordCorrente(e.target.value)}
                        toggleMask
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="password">Nuova Password</label>
                    <Password
                        inputId="password"
                        promptLabel="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        toggleMask
                    />
                </div>
                <div className="p-field">
                    <label htmlFor="passwordRipeti">Ripeti Nuova Password</label>
                    <Password
                        inputId="passwordRipeti"
                        promptLabel="Ripeti Nuova Password"
                        value={passwordRipeti}
                        onChange={(e) => setPasswordRipeti(e.target.value)}
                        toggleMask
                    />
                </div>
                <Button label="Cambia Password" onClick={handleChangePasswordEmail} />
            </Dialog>
        </div>
    );
};

export default QRCodeScanner;
